
import Vue, { PropType } from 'vue'
import { Broadcaster } from '~/models/broadcaster'
import MarkdownElement from '~/components/markdown/Element.vue'
import {
  SeriesDisplay,
  SermonDisplay,
  SermonMetadataExtendedInfo,
  SpeakerDisplay,
  StadiaMapStyle,
  WebcastDisplay,
} from '~/assets/ts/enums'
import { pojoToSermon, Sermon } from '~/models/sermon'
import { siteBroadcasterUrl } from '~/assets/ts/utils/urls'
import { getSermonsHelper } from '~/assets/ts/utils/sermons'
import { Webcast } from '~/models/webcast'
import { WebcastApi } from '~/apiclient/apiwebcasts'
import VerticalAlign from '~/components/_general/VerticalAlign.vue'
import WebcastPing from '~/components/_general/WebcastPing.vue'
import WebcastElement from '~/components/_general/WebcastElement.vue'
import SaLink from '~/components/_general/SaLink.vue'
import InlineIcon from '~/components/_general/InlineIcon.vue'
import SiteTitledSection from '~/components/site/TitledSection.vue'
import SiteLayoutWithSideContent from '~/components/site/layout/WithSideContent.vue'
import SiteSermonListWithTitle from '~/components/site/SermonListWithTitle.vue'
import HorizontalRule from '~/components/_general/HorizontalRule.vue'
import HomeTagElement from '~/components/broadcaster/HomeTagElement.vue'
import SermonElement from '~/components/_general/SermonElement.vue'
import { SermonApi } from '~/apiclient/apisermons'
import BroadcasterServiceTimes from '~/components/broadcaster/ServiceTimes.vue'
import MapElement from '~/components/map/Element.vue'
import { soloUrl } from '~/assets/ts/utils/solo'
import { BroadcasterNoticesApi } from '~/apiclient/apibroadcasters'
import { Notice } from '~/models/notice'
import BroadcasterNotice from '~/components/broadcaster/Notice.vue'
import BroadcasterRecentNotices from '~/components/broadcaster/RecentNotices.vue'
import SiteSeriesFilteredListWithTitle from '~/components/site/series/FilteredListWithTitle.vue'
import { SeriesRequestOptions } from '~/apiclient/apiseries'
import SiteFeaturedCommentElement from '~/components/site/featured/CommentElement.vue'
import { UserComment } from '~/models/usercomment'
import { CommentSortOptions, UserCommentApi } from '~/apiclient/apicomments'
import { PromiseAll } from '~/assets/ts/utils/misc'
import BroadcasterListenLine from '~/components/broadcaster/ListenLine.vue'
import BroadcasterDonateButton from '~/components/broadcaster/DonateButton.vue'
import BroadcasterPhone from '~/components/broadcaster/Phone.vue'
import BroadcasterPinnedArticles from '~/components/broadcaster/PinnedArticles.vue'
import BroadcasterContactButton from '~/components/broadcaster/ContactButton.vue'

export default Vue.extend({
  name: 'BroadcasterHome',
  components: {
    BroadcasterContactButton,
    BroadcasterPinnedArticles,
    BroadcasterListenLine,
    SiteFeaturedCommentElement,
    BroadcasterPhone,
    BroadcasterDonateButton,
    SiteSeriesFilteredListWithTitle,
    BroadcasterRecentNotices,
    BroadcasterNotice,
    MapElement,
    BroadcasterServiceTimes,
    SermonElement,
    HomeTagElement,
    HorizontalRule,
    SiteSermonListWithTitle,
    SiteLayoutWithSideContent,
    SiteTitledSection,
    InlineIcon,
    SaLink,
    WebcastElement,
    WebcastPing,
    VerticalAlign,
    MarkdownElement,
  },
  props: {
    broadcaster: {
      type: Object as PropType<Broadcaster>,
      required: true,
    },
  },
  data() {
    return {
      recentSermons: [] as Sermon[],
      recentSermonsLoaded: false,
      highlightedSermonPojo: undefined as SermonApi | undefined,
      welcomeSermonPojo: undefined as SermonApi | undefined,
      noticePojos: [] as BroadcasterNoticesApi[],
      commentPojo: undefined as UserCommentApi | undefined,
    }
  },
  async fetch() {
    await this.getNotices()
  },
  computed: {
    SermonDisplay() {
      return SermonDisplay
    },
    SpeakerDisplay() {
      return SpeakerDisplay
    },
    SeriesDisplay() {
      return SeriesDisplay
    },
    recentSermonsCount(): number {
      return 5
    },
    longAboutLength(): number {
      return 250
    },
    SermonMetadataExtendedInfo() {
      return SermonMetadataExtendedInfo
    },
    recentSeriesOptions(): SeriesRequestOptions {
      return {
        pageSize: 3,
        sort_by: 'last_updated',
      } as SeriesRequestOptions
    },
    notices(): Notice[] {
      return this.noticePojos.map((n) => new Notice(n)).filter((n) => n.recent)
    },
    recentNotice(): Notice | undefined {
      return this.notices.length ? this.notices[0] : undefined
    },
    recentNoticeCount(): number {
      return this.notices.length - 1
    },
    dark(): boolean {
      return this.$colorMode.value === 'dark'
    },
    mapStyle(): StadiaMapStyle {
      return this.dark
        ? StadiaMapStyle.AlidadeSmoothDark
        : StadiaMapStyle.AlidadeSmoothLight
    },
    highlightedSermon(): Sermon | undefined {
      return pojoToSermon(this.highlightedSermonPojo)
    },
    welcomeSermon(): Sermon | undefined {
      return pojoToSermon(this.welcomeSermonPojo)
    },
    webcastDisplay(): WebcastDisplay {
      return WebcastDisplay.Featured
    },
    webcast(): Webcast | undefined {
      if (!this.webcastApi) return undefined
      return new Webcast(this.webcastApi)
    },
    webcastApi(): WebcastApi | undefined {
      return this.$store.getters['webcasts/broadcasterWebcast'](
        this.broadcaster.id
      )
    },
    longAbout(): boolean {
      const about = this.broadcaster?.aboutUs
      if (!about) return false
      return about.length > this.longAboutLength
    },
    googleMapLink(): string {
      if (!this.broadcaster) return ''
      return `https://www.google.com/maps?q=${encodeURIComponent(
        this.broadcaster.address ?? ''
      )}&loc:${this.broadcaster.latitude}+${this.broadcaster.longitude}`
    },
    recentSermonsUrl(): string {
      return siteBroadcasterUrl(this.broadcaster, 'sermons')
    },
    recentSeriesUrl(): string {
      return siteBroadcasterUrl(this.broadcaster, 'series')
    },
    recentComments(): string {
      return siteBroadcasterUrl(this.broadcaster, 'comments')
    },
    comment(): UserComment | undefined {
      return this.commentPojo ? new UserComment(this.commentPojo) : undefined
    },
    broadcasterID(): string {
      return this.broadcaster.id
    },
  },
  async mounted() {
    await PromiseAll([
      this.getRecentSermons(),
      this.getHighlightedSermon(),
      this.getWelcomeSermon(),
      this.getComment(),
    ])
  },
  methods: {
    siteBroadcasterUrl,
    soloUrl,
    async getNotices() {
      const { results } = await this.$apiClient.getBroadcasterNotices(
        this.broadcaster.id
      )
      this.noticePojos = results
    },
    async getWelcomeSermon() {
      const sermonID = this.broadcaster.welcomeVideoID
      if (!sermonID) return
      this.welcomeSermonPojo = (await this.$apiClient.getSermon(
        sermonID
      )) as SermonApi
    },
    async getHighlightedSermon() {
      const { audioSermon } =
        await this.$apiClient.broadcasterHighlightedSermons(this.broadcaster.id)
      this.highlightedSermonPojo = audioSermon || undefined
    },
    async getRecentSermons() {
      if (!this.broadcaster.numberOfSermons) return
      try {
        await getSermonsHelper(this.$apiClient, this.recentSermons, {
          broadcasterID: this.broadcasterID,
          pageSize: this.recentSermonsCount,
          requireAudio: false,
        })
      } finally {
        this.recentSermonsLoaded = true
      }
    },
    async getComment(): Promise<void> {
      const { results } = await this.$apiClient.getFilteredComments({
        pageSize: 1,
        sortBy: CommentSortOptions.Random,
        recent: true,
        broadcasterID: this.broadcasterID,
      })
      this.commentPojo = results.pop()
    },
  },
})
