
import Vue, { PropType } from 'vue'
import SiteTitledSection from '~/components/site/TitledSection.vue'
import SiteDataTable, { TableData } from '~/components/site/DataTable.vue'
import { Broadcaster } from '~/models/broadcaster'
import PhoneNumber from '~/components/_general/PhoneNumber.vue'

export default Vue.extend({
  name: 'BroadcasterListenLine',
  components: { PhoneNumber, SiteDataTable, SiteTitledSection },
  props: {
    broadcaster: {
      type: Object as PropType<Broadcaster>,
      required: true,
    },
  },
  computed: {
    canListenLine(): boolean {
      return this.broadcaster.canWebcast && this.broadcaster.usa
    },
    phoneData(): TableData[] {
      return [
        { label: this.$t('Phone'), value: '(712) 432-3410', phone: true },
        { label: this.$t('CODE ID'), value: this.broadcaster.idCode },
      ]
    },
  },
})
